import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    window.location.href = "http://www.stefanmagicshow.com";
  },[])

  return (
    <div className="App">
     Loading...
    </div>
  );
}

export default App;
